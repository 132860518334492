@import '../variables';
@import '../mixins';

@import './customCheckboxMixin';
@import './customInputMixin';
@import './customRadioMixin';
@import './customSelectMixin';
@import './customSearchInputMixin';

$--font-size-11: 11px;
$--font-size-13: 13px;

.custom-form-item {
  .ant-picker {
    @include custom-input();
  }

  .ant-input {
    @include custom-input();
    &-number {
      @include custom-input-number();
    }
    &-password {
      @include custom-password-input();
    }
    &[disabled] {
      border-color: transparent
    }
  }

  .ant-radio {
    @include custom-radio();
  }

  .ant-checkbox {
    @include custom-checkbox();
  }

  &.ant-checkbox {
    @include custom-checkbox();
  }

  .ant-input-affix-wrapper > input.ant-input {
    border: none !important;
    outline: none !important;
  }
}

.ant-form-item {
  &-label {
    padding: 0 !important;
    label {
      font-size: $--font-size-11 !important;
      color: $--color-black-grey !important;

      .custom-form-item--disabled & {
        border-color: transparent;
        color: rgba($--color-black-grey, 0.3) !important;
      }

      @include font-weight(bold);
      text-transform: uppercase;
    }
  }

  &-explain-error {
    margin: 6px 0 12px;
    color: $--color-alarm-error !important;
    font-size: $--font-size-11;
    @include font-weight(bold);
  }

  &-has-error {
    label {
      color: $--color-alarm-error !important;
      &::before {
        color: $--color-alarm-error !important;
      }
    }
    .ant-picker,
    .ant-input {
      box-shadow: none;
      border: 2px solid $--color-alarm-error;

      &:active,
      &:focus,
      &:hover {
        border: 2px solid $--color-alarm-error;
        box-shadow: none;
      }

      &-password {
        border: 2px solid $--color-alarm-error !important;
        &:hover {
          &::before {
            color: $--color-alarm-error !important;
          }
        }
        .ant-picker,
        .ant-input {
          box-shadow: none;
        }
      }
    }
  }
}

/*Normal input (not in the form.item)*/
.custom-input.ant-input {
  @include custom-input();
}
.custom-input {
  .ant-radio {
    @include custom-radio();
  }
  .ant-checkbox {
    @include custom-checkbox();
  }
}
.custom-search-input {
  @include custom-search-input();
}
@import '../../../../../assets/scss/variables';

.scope-container {
  display: flex;
  padding-top: 60px;
}

.scope-introduction,
.scope-selection {
  width: 50%;
}

.scope-selection {
  display: flex;
  justify-content: flex-end;
}

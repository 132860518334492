@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.modal {
  max-width: 540px;

  :global(.ant-modal-body) {
    padding: 4rem;
    padding-bottom: 3.2rem;
  }
}

.modal__title {
  color: $--color-black-grey;
  font-size: 3rem;
  font-weight: 900;
  line-height: calc(36 / 30);
  text-transform: uppercase;
  margin-bottom: 17px;
}

.modal__title-left {
  display: flex;
  align-items: center;
}

.modal__description {
  color: $--color-black-grey;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: calc(22 / 13);
  margin-bottom: 16px;
}

.new-wishlist__label {
  color: $--color-black-grey;
  font-size: 1.1rem;
  line-height: calc(28 / 11);
  font-weight: 700;
  text-transform: uppercase;
}

.new-wishlist__input--no-margin {
  margin: 0;
}

.break-line {
  margin: 24px auto;
  border-top: 1px solid $--color-light-grey;
}

.modal__action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__action-button {
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0.8rem 1.3rem;
  letter-spacing: 0.47;
}

@include media-breakpoint-down(lg) {
  :global(.ant-modal-centered::before) {
    content: unset;
  }

  :global(.ant-modal-centered>.ant-modal) {
    display: inline-block;
    height: 100%;
  }

  .modal {
    margin: unset;
    padding: unset;
    max-width: 100vw;
    height: -webkit-fill-available;
    overflow: hidden;

    :global(.ant-modal-body) {
      padding: 3rem 1.6rem;
    }

    :global(.ant-modal-content) {
      height: 100%;
    }

    .modal__title-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      line-height: 1.2;
      margin-bottom: 0;

      .modal__close-button {
        padding-right: 0.7rem;
        margin-right: 8px;
      }

      .modal__title {
        font-size: 1.4rem;
        margin-bottom: 0;
      }

      .modal__save-button {
        background-color: transparent;
        color: #1890ff;
        font-weight: 800;
      }
    }

    .break-line {
      margin: 14px auto;
    }
  }
}

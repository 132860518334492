@import '../../../../../../assets/scss/mixins';
@import '../../../../../../assets/scss/variables';

.technicalUserDetails {
  .subTitle {
    @include font-weight(bold);
  }
  .editButton {
    margin-top: 42px;
  }

  .assignedRolesWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }

  .assignedRole {
    margin-right: 0;
    padding: 2px 6px;
    font-weight: 500;
  }
}

@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.wrapper {
  width: 100%;
  min-height: 50vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 30px;
}

.description {
  font-size: 13px;
  @include font-weight(normal);
}

.back-button {
  font-size: 13px;
  @include font-weight(normal);
}

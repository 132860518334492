@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.card-item__container--price,
.card-item__container--none-price {
  height: 100%;

  p {
    margin-bottom: 10px;
    line-height: 1;
    color: $--color-dark-grey;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .card-item__title {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.288;
    color: $--color-black;
    margin-bottom: 11px;
    @include ellipsis-texts(2);
    text-transform: none;
  }

  h2,
  h3,
  h4 {
    text-transform: capitalize;
  }
}

.card-item__container--none-price {
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

}

.card-item__container--price {
  max-height: 480px;
  max-width: 320px;

  h1 {
    color: $--color-black;
    font-size: 2.4rem;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:hover {
    h1 {
      color: $--color-dark-grey;
    }

    .kaercher-button {
      background: $--color-black-grey;
      color: $--color-yellow;
      border: solid 2px $--color-black-grey;
    }

    .hover-layer__wrapper {
      display: block;
      opacity: 1;
    }
  }
}

.card-item__container--price.ant-card-bordered {
  border: none;
}

.category__name {
  flex: 1;
  margin-bottom: 6px;
  color: $--color-black-grey;
  font-size: 1.1rem;
  font-weight: 400;
}

.card-item__item-number.link-normalize {
  height: 28px;
  margin-bottom: 2px;
}

.card-item__date {
  background-color: $--color-black-grey;
  color: $--color-white;
  padding: 0.3125em 0.5em;
  border-radius: 1px;
  max-width: fit-content;
}

.card-item__btn-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  .ant-btn:first-child {
    margin-bottom: 17px;
  }

  .ant-btn>span {
    white-space: break-spaces;
  }
}

.card-item__btn-wrapper__icon__wrapper {
  width: 24px;
}

.card-item__excluded-massages-wrapper,
.card-item__excluded-massages-wrapper--mobile {
  z-index: 1;
  position: absolute;
  top: 80px;
  right: 18px;
  left: 17px;
  padding: 15px 18px;
  background-color: $--color-bright-grey;
  font-weight: 400;
  font-size: 1.3rem;
  color: $--color-black-grey;
  line-height: 1.692;
  white-space: pre-wrap;
  word-break: break-word;
}

.card-item__excluded-massages-wrapper--mobile {
  top: 50px;
}

.card-item__material-number--wrapper {
  height: 10px;
}

.card-item__item-number--wrapper {
  display: flex;
  justify-content: space-between;
}

.list-price__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-price__wrapper__title {
  text-transform: capitalize;
  font-size: 1.1rem;
  font-weight: 500;
  color: $--color-dark-grey;
}

.list-price__wrapper__price {
  font-size: 1.6rem;
  font-weight: 700;
  color: $--color-black-grey;
}

.card-item__container--price--hover-border {
  &:hover {
    .ant-card-body {
      border: 3px solid $--color-yellow;
    }
  }
}

.card-item__container--price__link {
  text-align: center;
  margin-top: 30px;

  a {
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 2px;
    line-height: 1;
    border-bottom: solid 1px rgba(0, 0, 0, 0.85);
  }
}

.product-card__content-container,
.card-item__container--price {
  a {
    display: block;
  }
}

.product-card-modified {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 3px solid transparent;
  background: #fff;
  transition: border 200ms;

  &:hover {
    border-color: #ffed00;
  }

  >.add-to-wishlist {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    height: 24px;
    min-width: 24px;
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;
  }

  .card-item__title,
  .card-item__item-number,
  .list-price__wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.product-card__content-container {
  height: 100%;

  .ant-card-body {
    height: inherit;
    padding: 14px;
    display: flex;
    flex-direction: column;
    position: relative;

    >.add-to-wishlist {
      height: 20px;
      min-width: 20px;
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 2;
    }
  }
}

.product-card__content-image {
  max-width: 140px;
  min-width: 140px;
  margin-right: 11.8px;
}

.product-card__content--right-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  min-width: 0;
}

.product-card__content-category {
  margin-bottom: 10px;
  font-size: 1.6rem;
  line-height: 1.28;
  color: $--color-black;
  @include font-weight(medium);
  @include ellipsis-texts(2);
}

.product-card__content-name,
.product-card__content-name--service-case {
  margin-bottom: 33px;
  font-size: 1.8rem;
  line-height: 1.28;
  color: $--color-black;
  @include ellipsis-texts(2);
}

.product-card__content-name--service-case {
  margin-bottom: 20px;
}

.product-card__content-material-number,
.product-card__content-serial-number {
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.28;
  color: $--color-dark-grey;
  margin-bottom: 0;
}

.product-card__content-date {
  background-color: $--color-black-grey;
  color: $--color-white;
  padding: 2px 8px;
  border-radius: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.product-card__content--lower-wrapper {
  display: flex;
  justify-content: space-between;
}

.product-card__list-price-wrapper {
  display: inline-flex;
  flex-direction: column;
  color: $--color-black-grey;
}

.product-card__list-price-text {
  font-size: 1.1rem;
  line-height: 1.28;
  text-transform: uppercase;
}

.product-card__list-price-number {
  font-size: 1.8rem;
  line-height: 1.28;
  font-weight: 900;
}

.hover-layer__wrapper {
  width: 100%;
  display: block;
  opacity: 0;

  .functions__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 8px;
    height: 46px;
  }

  .ant-checkbox-wrapper {
    text-align: left;
    font-size: 1.3rem;
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  button {
    width: 59px;
    height: 46px;
    background-color: $--color-yellow;
  }
}

@media screen and (max-width: 576px) {
  .product-card__content-container {
    .ant-card-body {
      padding: 7px;
    }
  }

  .product-card__content-image {
    max-width: 125px;
    min-width: 125px;
    margin-right: 9px;
  }

  .product-card__content-name {
    margin-bottom: 19px;
  }
}

@media screen and (max-width: $--ipad--landscape-width) {
  .card-item__container {
    transition: none;

    .ant-card-cover {
      border-bottom: none;
      overflow: hidden;
    }

    &:hover {
      box-shadow: none;
    }
  }

  .card-item__btn-wrapper {
    justify-content: inherit;
  }

  .card-item__btn-wrapper__icon--machine-page {
    bottom: 205px;
    left: 90%;
  }
}

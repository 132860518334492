@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;

  ol,
  ul {
    list-style-position: inside;
    padding-left: 0;
  }
  ol.headline {
    &-1 {
      li {
        &::marker,
        & > span {
          font-size: 15px;
          @include font-weight(medium);
        }
      }
    }
    &-2 {
      li {
        span {
          font-size: 14px;
        }
      }
    }
  }
  ul {
    list-style: none;
    li::before {
      content: '\2022';
      color: $--color-black;
      @include font-weight(bold);
      display: inline-block;
      width: 1em;
    }
  }
}

.containerWidth {
  @media screen and (max-width: $--width-page-container) {
    width: $--max-width-main-layout;
  }
  @media screen and (max-width: $--ipad--landscape-width) {
    width: 95%;
  }
  width: 50%;
}

.info {
  display: flex;
  flex-direction: column;
}
.justityText {
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
}
.pd10 {
  padding: 10px 0;
}
.pd10Top {
  padding-top: 10px;
}

.dataPolicySection {
  margin-bottom: 30px;
}

.cookieSettingsBlock {
  display: flex;
  flex-direction: column;
}

.settingButton {
  display: block;
  margin: 0 auto 15px;
}

@import "../variables";
@import "../mixins";

$--font-size-11 : 11px;
$--font-size-13 : 13px;

@mixin custom-radio(){
  &-wrapper:hover .ant-radio, &:hover .ant-radio-inner, &-input:focus + .ant-radio-inner {
    border-color: $--color-dark-grey !important;
  }
  &-wrapper {
    font-size: $--font-size-13;
    > span:not(.ant-radio) {
      position: relative;
      top: -3px;
    }
  }
  &:hover {
    .ant-radio-inner {
      background-color: $--color-black-grey !important;
      &::after {
        transform: scale(1);
        opacity: 1;
        /* transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);*/
      }
    }
  }
  &-inner {
    width: 24px;
    height: 24px;
    border: 2px solid $--color-dark-grey;

    &::after {
      width: 8px;
      height: 8px;
      top: 14px;
      left: 14px;
      background-color: $--color-yellow;
    }
  }
  &-checked {
    &:hover {
      .ant-radio-inner {
        background-color: $--color-white !important;
        &::after {
          transform: scale(1);
          opacity: 1;
          background-color: $--color-black-grey;
        }
      }
    }
    .ant-radio-inner {
      border-color: $--color-black-grey !important;
      &::after {
        background-color: $--color-black-grey;
        transform: scale(1);
      }
    }
  }
  &-disabled {
    .ant-radio-inner {
      background-color: $--color-light-grey;
      border: none;
      &::after {
        top: 8px;
        left: 8px;
      }
    }
    &:hover {
      .ant-radio-inner {
        background-color: $--color-light-grey;
        border: none;
        &::after {
          transform: scale(0);
          opacity: 0;
        }
      }
    }
  }
}

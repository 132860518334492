@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.title-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.owner-name {
  @include font-weight(bold)
}

.wishlist-actions-container {
  display: inline-flex;
}

.actions-btn {
  margin-left: 24px!important;
  svg {
    width: 24px;
    height: 24px;
  }
}

.header-buttons {
  margin-left: 24px;
}

.mobile-actions-section {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $--color-white;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  padding: 15px 35px 35px;
  width: 100%;
  z-index: 10;

  .buttons-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      min-width: 200px;
      @media screen and (max-width: 750px) {
        min-width: 160px;
      }
    }
  }
}

@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixins';

.title {
  font-size: 11px;
  @include font-weight(bold);
  line-height: 28px;
  color: #757575;
  text-transform: uppercase;
}

.value {
  @include font-size(normal);
  @include font-weight(medium);
  line-height: 22px;
  color: $--color-black-grey;
}

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
  outline: none;

  + .wrapper {
    margin-left: 8px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.icon + .content,
.content + .icon {
  margin-left: 8px;
}

.icon {
  display: inline-block;
  width: 16px;
  text-align: center;
}

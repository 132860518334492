@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.wrapper {
  position: relative;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.error-messages {
  position: absolute;
  top: 100%;
  left: 0;
  color: $--color-alarm-error;
}
.input {
  max-width: 300px;
}
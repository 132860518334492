@import '../../assets/scss/variables';

:global {
  .ant-dropdown-open {
    background: red;
  }

  .anticon.anticon-down {
    font-size: 14px;
  }
}

.iconsWrapper {
    display: inline-flex;
    flex-direction: column;
    margin-right: 9px;

    &:hover {
      color: $--color-black;
    }
  }

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}
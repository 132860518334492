@import '../../assets/scss/variables';

.sap-log__wrapper {
  width: 70%;
  background-color: $--color-anthracite-pro;
  opacity: 0.9;
  height: 500px;
  min-height: 500px;
  position: fixed;
  bottom: 0;
  z-index: 20;
  padding: 15px 15px 2px 15px;
}

.sap-log__list {
  margin-top: 2px;
  padding: 3px;
  border: $--color-white solid 1px;
  overflow-y: auto;
  height: 225px;
}

.sap-log__list--item {
  word-break: break-all;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 1rem;
  color: $--color-white;
  line-height: 1.2;
}

@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.ConfigurationManagement {
  :global {
    .b2bec-info-layout {
      &__heading {
        padding-bottom: 0;
      }
    }
  }
  .TopBar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    @media screen and (max-width: $--small-device-width) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

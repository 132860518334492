@import '../../../../assets/scss/variables';

.autocomplete {
  width: 100%;
}

.search {
  :global {
    .ant-input-prefix {
      margin-right: 0;
    }

    .ant-input-lg {
      color: $--color-black-grey;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: calc(22 / 13);
    }

    .ant-input {
      padding-left: 0.8rem !important;
    }

    .ant-input-affix-wrapper-lg {
      padding-left: 0.6rem;
    }

    .ant-input::placeholder {
      color: $--color-dark-grey;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: calc(22 / 13);
    }

    & {
      .ant-input-group {
        .ant-input-group-addon:last-child {
          left: 0;
        }
      }
    }
  }

  &:hover, &:focus {
    outline: none;
    border-color: $--color-black-grey;
    box-shadow: unset;
    border-right-width: 2px !important;
  }
}

.icon {
  transform: rotateY(180deg); 
}

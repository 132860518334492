@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.orderDetailsTable {
  :global {

    table {
      border-collapse: collapse;
    }

    .ant-table-summary tr td {
      padding: 10px;
      text-transform: uppercase;
      font-size: 13px;
    }

    .base-table .ant-table-summary > tr > td.ant-table-cell {
      background-color: #fff;
    }

    .ant-table-summary tr.total-section td {
      background: #ededed!important;
      font-weight: 700;
      padding: 20px;
      font-size: 13px;
    }

    .ant-table-thead {
      > tr {
        > th.ant-table-cell {
          background-color: #ededed;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }

    .ant-table-tbody {
      > tr {
        > td {
          border-top: 5px solid #fff;
          background-color: #f6f6f6;
          a {
            color: #333;
          }
        }
      }
      > tr:last-child {
        > td {
          border-bottom: 1px solid #969696;
        }
      }

      > tr.ant-table-row:hover {
        > td {
          cursor: pointer;
        }
      }
    }

    .ant-table-row {
      vertical-align: top;
      font-size: 13px;
      color: $--color-black-grey;
      &:last-child {
        td {
          border-bottom: solid 8px $--color-light-grey !important;
        }
      }
    }

    .ant-table-summary {
      tr {
        td {
          padding: 10px;
          text-transform: uppercase;
          font-size: 13px;
        }

        &.total-section {
          td {
            background: $--color-light-grey !important;
            @include font-weight(bold, 700);
            padding: 20px;
            font-size: 13px;
          }
          &.vat {
            td {
              @include font-weight(normal);
              padding: 10px 20px;
            }
          }
        }

        &:nth-last-child(1) {
          td {
            padding-bottom: 40px;
          }
        }
      }
    }

    .unavailable-product__text {
      padding: 4px 8px;
      margin-top: 6px;
      margin-bottom: 0;
      background: $--color-light-grey;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      color: $--color-black-grey;
    }

    .text--bold {
      @include font-weight(bold);
    }
  }
}
@import '../../../../assets/scss/variables';

:global {
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 2px solid $--color-black-grey;
  }

  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border: 2px solid $--color-black-grey;
    box-shadow: none;
  }

  .ant-input-affix-wrapper {
    border: 2px solid $--color-dark-grey;
  }
}

.shippingNotes {
  margin-bottom: 10px;
  border: none !important;
}
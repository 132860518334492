.wrapper {
  :global {
    .ant-table-tbody {
      tr.ant-table-row-level-1 {
        td:last-child {
          padding-left: 32px;
        }
      }
    }
  }
}
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.portfolio-buttons-container {
  display: flex;
  align-items: center;

  > span {
    display: block;
    margin-left: 8px;

    & div {
      height: 100%;
    }
  }
}

.header-buttons {
  min-width: 120px;
  max-height: 50px;

  &:disabled {
    @include iconRules() {
      fill: #b3b3b3;
    }
  }

  &:hover:not(:disabled) {
    @include iconRules() {
      fill: $--color-yellow;
    }
  }
}

.header-buttons:not(:first-child) {
  margin-left: 8px;
}

@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.button {
  min-width: 118px;
  margin-right: 1px;
  padding: 17px 10px;
  @include font-size(normal);
  @include font-weight(bold);
  line-height: 17px;
  border: 2px solid transparent;
  text-transform: uppercase;
  background-color: $--color-light-grey;
  cursor: pointer;
  &:hover,
  &.selected {
    @include button-hover();
  }
}

@import '../../../../assets/scss/variables';

.button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding-top: 0.8rem;

  .ant-spin {
    color: $--color-black-grey;
  }
}
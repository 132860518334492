@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.uploadBlock {
  display: flex;

  .customButton {
    &:disabled {
      @include iconRules() {
        fill: #b3b3b3;
      }
    }

    &:hover:not(:disabled) {
      @include iconRules() {
        fill: $--color-yellow;
      }
    }
  }
}

@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.detailPage {
  margin: 0 -17px;
  margin-top: 18px;

  h1 {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .container {
    padding: 17px;
  }

  .header {
    position: relative;
    background-color: #f5f5f5;
  }

  .userDataHeader {
    font-size: 11px;
    font-weight: 700;
    line-height: 28px;
    color: #757575;
  }

  .userDataContent {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    color: #2b2b2b;
  }

  .content {
    padding: 23px 0;

    .container {
      display: flex;
      flex-direction: column;
      row-gap: 50px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .detailPage {
    margin: 0 calc(-21% - 5px);
    margin-top: 12px;

    .header {
      padding: 32px 0;
    }

    .container {
      width: 70%;
      margin: 0 auto;
      padding: 0;
    }

    .content {
      .container {
        flex-direction: row;
        row-gap: 16px;
      }
    }
  }
}

@import '../../../../assets/scss/variables';

.wrapper {
  margin-left: 24px;
}

.title {
  color: $--color-black-grey;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: calc(28 / 11);
  text-transform: uppercase;
}

.select {
  width: 150px;
}
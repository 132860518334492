@import '../../../../assets/scss/variables';

.container {
  width: 100%;
  background: $--color-light-grey;
  border-top: solid 1px #dcdcdc;
  white-space: pre-wrap;

  a {
    color: #333;
  }

  h3 {
    color: $--color-black-grey;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
    font-size: 1.1rem;
    line-height: 2;
  }
}

.top__wrapper {
  width: $--max-width-main-layout;
  margin: 0 auto;
  padding: 28px 0;
}

.col_wrapper {
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.bottom__wrapper {
  background: $--color-white;
  padding: 20px 0;

  h3 {
    width: $--max-width-main-layout;
    margin: 0 auto;
    margin-bottom: 0;
    font-size: 1.1rem;
  }
}

.business-workspace {
  text-align: center;
  border-bottom: 1px solid #dcdcdc;
  padding: 11px 10px 9px;
  color: $--color-black-grey;

  span:first-of-type {
    font-size: 1.2rem;
    font-weight: 900;
    text-transform: uppercase;
  }

  span:last-of-type {
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.SSL-icon {
  padding-top: calc(900 / 1600 * 100%);
}

@media screen and (max-width: $--ipad--landscape-width) {
  .top__wrapper {
    width: inherit;
    padding: 25px 36px 45px;
  }

  .col_wrapper {
    margin-bottom: 25px;

    &:nth-of-type(3) {
      margin-bottom: 0;
    }
  }

  .bottom__wrapper {
    padding: 13px 17px;

    h3 {
      width: 100%;
    }
  }
}

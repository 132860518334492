.sparePartsProductInfo {
  padding-right: 70px;
  margin-bottom: 48px;
  font-size: 14px !important;
  font-weight: bold;

  .productImage {
    width: 150px;
  }
}

.error {
  color: red;
}

@import '../../../assets/scss/variables';

.sparePartsFinderInput {
  min-height: 300px;

  input {
    padding-right: 40px !important;
  }

  .label {
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 2.545;
    font-weight: 700;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .greenCheck {
    position: absolute;
    right: 65px;
    top: 10px;
  }

  .error {
    position: absolute;
    color: $--color-alarm-error;
    right: 65px;
    top: -2px;
    font-size: 3.5rem;
  }

}

.infoButton {
  border: none;
  background-color: transparent;

  img {
    width: 24px;
    height: 24px;
    margin-top: 8px;
  }

  @media screen and (max-width: $--very-small-device-width) {
    position: absolute;
    right: 0;
    top: -40px;
  }
}

@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixins';

.container {
  display: flex;
  flex-direction: row;
}

.input {
  color: $--color-black-grey;
  font-size: 1.3rem;
  font-weight: 500;
  border: 2px solid $--color-dark-grey;
  padding-right: 11px;
  padding-left: 15px;
  margin-right: 8px;
  width: 211px;
  max-width: 211px;

  &:focus-visible {
    outline: none;
  }
}

.redeem__button {
  > button {
    height: 46px;

    // ----- FIX OVERLAP BUTTON -----
    // for basic lang: en, de
    width: max-content;
    // for longer cases
    > span {
      @include ellipsis-texts(1);
    }
    // -----------------------------

    &:disabled,
    &:hover:disabled {
      background: transparent;
      border: solid 2px $--color-black-grey;
      color: $--color-black-grey;
    }
  }
}

@include media-breakpoint-down(md) {
  .container {
    flex-direction: column;
  }

  .input {
    max-width: none;
    height: 46px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .redeem__button {
    max-width: none;
    width: 100%;

    > button {
      width: 100%;
    }
  }
}

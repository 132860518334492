.SparePartsFinder {
  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 2;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  .description {
    margin-bottom: 24px;
    width: 510px;
    max-width: 100%;
    font-size: 13px;
    line-height: 22px;
  }

  :global(.b2bec-info-layout__content__wrapper) {
    overflow: hidden;
    margin-top: 32px;
  }
}

@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.submenu-mobile {
  &__btn {
    @include container-normalize;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: map-get($spacers, 3) map-get($spacers, 4);
    font-size: 16px;
    @include font-weight(bold);
    color: $--color-black-grey;
    text-transform: uppercase;
  }
}

.header-menu-drawer {
  &__sub-drawer {
    .ant-drawer-body {
      padding: 0 map-get($spacers, 4);
    }
  }
}

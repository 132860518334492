.wrapper {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;

  :global {
    .custom-form-item {
      margin-bottom: 0;
      margin-right: 20px;
    }

    .ant-input {
      margin-bottom: 0;
    }
  }
}
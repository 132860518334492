.contentWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: 16px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.selectWrapper {
  flex: 2;
}

.searchWrapper {
  flex: 3;
}

.runWrapper {
  flex: 1
}

.button {
  padding: 1.05rem 3.5rem;
}

.selectWrapper {
  max-width: 300px;
}

.selectBox {
  width: 100%;
  
  :global {
    .ant-select-dropdown {
      z-index: 2;
    }
  }
}

.title {
  font-size: 3rem;
  font-weight: 900;
  line-height: 38px;
}

.searchContainer {
  width: 338px;
  margin-bottom: 40px;
}

.tableContainer {
  margin-bottom: 32px;
}
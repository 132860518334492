@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.input {
  border: 2px solid $--color-dark-grey;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  padding-left: 1.5rem;
  width: 100%;
  margin-bottom: 24px;
  text-align: left;

  &, &::placeholder {
    color: $--color-dark-grey;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: calc(22 / 13);
  }
  
  &:focus-visible {
    outline: none;
  }
}

@include media-breakpoint-down(lg) { 
  .input, input::placeholder {
    font-size: 1.1rem;
  }
}
@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.wrapper {
  margin-bottom: 30px;

  :global {
    td.ant-table-column-sort {
      background: #fff;
    }
    .ant-table-column-sorter,
    .ant-table-column-sorter-full {
      display: none;
    }
    .ant-table-thead {
      .ant-table-cell:last-child {
        padding-right: 16px;
      }
 
      .ant-table-column-sorters > span > span > span {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: calc(28 / 14);
      }      

      tr {
        .ant-table-cell > span {
          font-size: 1.4rem;
          font-weight: 700;
          line-height: calc(28 / 14);
        }
      }
    }

    .ant-table-thead {
      tr {
        th.ant-table-cell {
          padding-top: 1.8rem;
          padding-bottom: 1.8rem;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          color: $--color-black-grey;
          a {
            color: $--color-black-grey;
            display: block;
          }
        }
      }
    }
  }
}

.text {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: calc(22 / 13);
}

.saleOrderNumber {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: calc(22 / 13);
}

.total {
  font-size: 1.3rem;
  font-weight: 700;
}

.actionWrapper {
  display: flex;
  justify-content: flex-end;
}

@include media-breakpoint-down(lg) {
  .actionButton:first-of-type {
    margin-right: 0;
  }
}
@import '../../assets/scss/variables';

.wishlistItem {
  border: 1px solid $--color-dark-grey;
  max-width: 100%;
  color: $--color-black-grey;
  line-height: 1.125;
  padding: 24px 0 9px 0;
  cursor: pointer;
  min-height: 326px;

  a {
    color: $--color-black-grey;
  }

  .header {
    padding: 0 24px;
    margin-bottom: 23px;
    display: flex;
    justify-content: space-between;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    margin: 0;
    margin-bottom: 4px;
    height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .infoText {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 4px;
    display: block;
  }

  .productList {
    display: flex;
    padding: 0 9px;
    flex-wrap: wrap;
    grid-gap: 12px;
    height: 210px;
    overflow: hidden;
  }

  .productItem {
    width: 98px;

    img {
      width: 100%;
    }
  }
}

.wishlistActions {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    cursor: pointer;
    font-size: 13px;
    padding: 6px 16px;
    line-height: 22px;

    &:hover {
      background-color: $--color-black-grey;
      color: $--color-yellow;
    }
  }
}

.overlay {
  padding: 0;

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner-content) {
    width: 200px;
    padding: 6px 0;
  }
}


.wishlistItemInfo {
  display: block;
  overflow: hidden;
}
@import '../../../assets/scss/variables';

.inputContainer {
  position: relative;
}

.input {
  background: transparent;
  border: 2px solid $--color-anthracite-pro;
  color: $--color-black-grey;
  font-size: 1.3rem;
  line-height: 16px;
  font-weight: 500;
  padding: 12px;
  padding-left: 40px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  width: 100%;

  &:focus,
  &:hover {
    border-color: $--color-black-grey;
  }

  &::placeholder {
    color: $--color-anthracite-pro;
  }
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.large {
  // width: 300px;
  height: 40px;
}
@import '../../../../assets/scss/variables';

.container {
  :global {
    .ant-table-column-sorter, .ant-table-column-sorter-full {
      display: none;
    }
  }


}

.table {
  :global {
    tr.ant-table-expanded-row > td {
      border: 1px solid $--color-dusty-gray;
      background-color: #fafafa;

      > .base-table {
        overflow: hidden;
      }
    }

    tr.ant-table-row-level-0 {
      .ant-checkbox-checked::after {
        border: none;
      }
    }

    .ant-checkbox-indeterminate {
      border-color: $--color-black !important;
      .ant-checkbox-inner {
        border-color: $--color-black !important;
      }
      .ant-checkbox-inner::after {
        background-color: $--color-black;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        border: 0;
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: ' ';
      }
    }
  }

  .expandedRow {
    :global {
      td {
        &:first-child {
          border-left: 1px solid $--color-dusty-gray;
        }
        &:last-child {
          border-right: 1px solid $--color-dusty-gray;
        }
        border-top: 1px solid $--color-dusty-gray;
      }
    }
  }
}

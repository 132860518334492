.addProduct {
  height: 387px;

  .header {
    display: block;
    font-size: 30px;
    font-weight: 900;
    color: #2b2b2b;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 25px;

    display: flex;
    justify-content: space-between;
  }

  .closeButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
@import "../variables";
@import "../mixins";

$--font-size-11 : 11px;
$--font-size-13 : 13px;

@mixin custom-checkbox(){
  &-wrapper:hover .ant-checkbox, &:hover .ant-checkbox-inner, &-input:focus + .ant-checkbox-inner {
    border-color: $--color-black !important;
  }
  &-wrapper {
    display: flex;
    > span:not(.ant-checkbox) {
      font-size: $--font-size-13;
      @include font-weight(medium);
    }
    &:hover:not(.ant-checkbox-wrapper-disabled) {
      .ant-checkbox-inner {
        background-color: $--color-white;
        border-color: $--color-black-grey !important;
        &::after {
          background-color: $--color-white;
          border-color: $--color-white;
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
    &-checked {
      &:hover:not(.ant-checkbox-wrapper-disabled) {
        .ant-checkbox-inner {
          background-color: $--color-black-grey !important;
          border-color: $--color-black-grey !important;
          &::after {
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
            opacity: 1;
            background-color: $--color-black-grey !important;
            border-color: $--color-yellow !important;
          }
        }
        .ant-checkbox-checked::after {
          border: none;
        }
      }
    }
  }
  &:hover:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
      background-color: $--color-white !important;
      border-color: $--color-black-grey;
      &::after {
        background-color: $--color-white;
        border-color: $--color-white;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
      }
    }
  }
  &-inner {
    width: 24px;
    height: 24px;
    border: 2px solid $--color-black-grey;

    &::after {
      top: 9px;
      left: 6px;
      width: 5px;
      height: 12px;
      border-color: $--color-yellow;
    }
  }
  &-checked {
    .ant-checkbox-inner {
      background-color: $--color-black-grey;
      border-color: $--color-black-grey;
      &::after {
        top: 9px;
        left: 6px;
        width: 5px;
        height: 12px;
        background-color: $--color-black-grey;
        border-color: $--color-yellow;
      }
    }
    &:hover:not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        background-color: $--color-black-grey !important;
        border-color: $--color-black-grey !important;
        &::after {
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
          opacity: 1;
          background-color: $--color-black-grey !important;
          border-color: $--color-yellow !important;
        }
      }
      &::after {
        border: 1px solid $--color-black-grey;
      }
    }
  }
  &-disabled {
    .ant-checkbox-inner {
      background-color: $--color-light-grey;
      border-color: transparent !important;
      &::after {
        background-color: $--color-light-grey;
        border-color: rgba($--color-black, 0.3) !important;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        background-color: $--color-light-grey !important;
        border-color: transparent !important;
        &::after {
          background-color: $--color-light-grey;
        }
      }
    }
  }

  &.ant-checkbox-indeterminate {
    border-color: $--color-black !important;
    .ant-checkbox-inner {
      border-color: $--color-black !important;
    }
    .ant-checkbox-inner::after {
      background-color: $--color-black;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      border: 0;
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: ' ';
    }
  }

  &.ant-checkbox-indeterminate:hover:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    border-color: $--color-black-grey;
    &::after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      background-color: $--color-black-grey !important;
      border-color: $--color-yellow !important;
      content: ' ';
    }
  }
}

@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.rentalTitle {
  display: flex;
  justify-content: space-between;
  > h1 {
    font-weight: 900;
    font-size: 30px;
    line-height: 1.2;
    color: $--color-black-grey;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.wrapper {
  :global {
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: $--color-black-grey;
    }
  }
}

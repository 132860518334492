@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixins';

.header-container {
  display: inline-flex;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (max-width: 750px) {
    display: inline-block;
    margin-bottom: 0;
  }
}

.total-products {
  width: 65%;

  @media screen and (max-width: 750px) {
    width: 100%;
  }

  h2 {
    @include font-weight(bold);
    line-height: 1.4;
  }
}

.search-product {
  width: 35%;
  @media screen and (max-width: 750px) {
    width: 100%;
    margin-top: 30px;
  }
}

.empty-result {
  margin: 0 auto;
}


@import '../../assets/scss/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
}

.wrapper {
  z-index: 1;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.text {
  display: none;
}

@media screen and (max-width: 768px) {
  .container {
    padding-right: 0;
  }

  .wrapper {
    width: 100%;

    > svg {
      height: 20px;
      width: 20px;
    }
  }

  .text {
    display: inline-block;
    text-transform: uppercase;
    margin-right: 11px;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

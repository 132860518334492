@import '../../../../../assets/scss/variables';

.alphabet-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:not(:first-of-type) {
    width: 15px;
  }

  font-weight: 700;
  font-size: 11px;
  line-height: 2.546;
  color: $--color-black-grey;
}

.alphabet-button--selected {
  text-decoration: underline;
}

@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.model__wrapper {
  max-width: 540px;
  max-height: 800px;

  :global(.ant-modal-body) {
    padding: 4rem 4rem 3.2rem;
  }
}

.title {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.inputField {
  margin-bottom: 0px;
}

.label {
  font-weight: 700;
  font-size: 11px;
  line-height: 2.545;
  text-transform: uppercase;
}

.autocomplete {
  margin-bottom: 20px;
  width: 100%;
}

.list {
  height: 250px;
  overflow: auto;
}

.disableList {
  cursor: pointer;
}

.wishlistItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: -5px;
}

.wishlistItem_input {
  width: 100%;
  margin-bottom: 10px;
  input {
    border: none;
    width: 100%;
  }
  &:focus-visible {
    outline: none;
  }
  input:focus-visible {
    outline: none;
  }
  :global(.ant-form-item-control:focus-visible), :global(.ant-form-item-control-input:focus-visible), :global(.ant-form-item-control-input-content:focus-visible) {
    outline: none;
  }
}

.disableBackground {
  background-color: $--color-anthracite-pro;
}

.disableBackground:focus-visible {
  outline: none;
}

.emailSpan {
  cursor: not-allowed;
}

.wishlistItem_deleteButton {
  margin-top: 5px;
  width: 16px;
  height: 14px;
}

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $--color-light-grey;
  padding-top: 24px;
  margin-top: 10px;
}

@include media-breakpoint-down(lg){

  .model__wrapper {
    display: contents !important;

    :global(.ant-modal-body) {
      padding: 4rem 2rem 4rem;
    }

    :global(.ant-modal-centered::before) {
      content: unset;
    }

    :global(.ant-modal-content) {
      height: 100vh;
    }

    :global(.ant-modal-body) {
      padding: 3rem 1.6rem;
    }
  }



  .modal {
    max-width: 100vw;
    height: 100%;
    border: 0;
    border-radius: 0;
    margin: unset;
    padding: unset;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  .divider {
    width: calc(100% + 800px);
    margin-left: -400px;
    height: 2px;
    background-color:  rgba(0, 0, 0, 0.2);
  }

  .list {
    margin-top: 20px;
  }

  .wishlistItem {
    padding: 5px 0;
  }
}


@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.loading-wrapper {
  padding: 0 15px;
}

.dot--flashing {
  position: relative;

  @include dot;

  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -$--dot-spacing;

    @include dot($bg-color: $--color-yellow);

    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: $--dot-spacing;

    @include dot($bg-color: $--color-yellow);

    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: $--color-yellow;
  }

  50%,
  100% {
    background-color: lighten($--color-yellow, 35%);
  }
}
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.wishlistOverview {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.desktop {
    .title {
      display: block;
      .titleActions {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  h1 {
    font-weight: 900;
    font-size: 30px;
    line-height: 1.2;
    color: $--color-black-grey;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .wishlistList {
    display: flex;
    flex-wrap: wrap;
  }
}

.searchInputContainer {
  margin-bottom: 43px;

  // TODO
  :global(.ant-input-affix-wrapper) {
    color: $--color-black-grey;
    font-weight: 500;
    font-size: 13px !important;
    border: 2px solid $--color-dark-grey;
    padding: 11px 11px 11px 15px !important;
    margin-bottom: 10px;
    padding: 0 !important;
    padding-left: 11px !important;
    margin-bottom: 0;
    border: 2px solid $--color-dark-grey !important;

    :global(.ant-input) {
      padding: 11px 0;
    }
  }

  .desktop & {
    display: flex;
    justify-content: flex-end;
    :global(.ant-input-affix-wrapper) {
      max-width: 320px;
    }
  }
}

.addButton {
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 3px solid transparent;
  background: #fff;
  transition: border 200ms;
  height: 100%;
  max-height: 480px;
  max-width: 320px;
  position: relative;

  &:hover {
    border-color: #ffed00;
    h1 {
      color: $--color-dark-grey;
    }

    .kaercher-button {
      background: $--color-black-grey;
      color: $--color-yellow;
      border: solid 2px $--color-black-grey;
    }

    .hover-layer {
      display: block;
      opacity: 1;
    }

    .ant-card-body {
      border: 3px solid $--color-yellow;
    }
  }

}

.heart-container {
  position: absolute;
  z-index: 1;
}

.heart {
  background-color: transparent !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  &:hover {
    border: none;
  }
}

.imageWrapper {
  position: relative;

  .quantity {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
  }

  .quantity-input {
    width: 60px !important;
  }
}


.product-card-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.288;
  color: $--color-black;
  margin-bottom: 11px;
  @include ellipsis-texts(2);
  text-transform: none;
  padding: 0 8px;
}

.product-card-item-number {
  margin-bottom: 10px;
  line-height: 1;
  color: $--color-dark-grey;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  padding: 0 8px;
}

.list-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}

.list-price-title {
  text-transform: capitalize;
  font-size: 11px;
  font-weight: bold;
  color: $--color-dark-grey;
}

.list-price-price {
  @include font-size(medium);
  font-weight: bold;
  color: $--color-black-grey;
}

.hover-layer {
  width: 100%;
  display: block;
  opacity: 0;

  .functions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 8px;
    height: 46px;
  }

  .ant-checkbox-wrapper {
    text-align: left;
    font-size: 13px;
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  button {
    width: 59px;
    height: 46px;
    background-color: $--color-yellow;
  }
}

.link-normalize {
  height: 28px;
  margin-bottom: 2px;
}

.left-wrapper {
  margin-right: 10px;
}

.quantity {
  @media screen and (max-width: 750px) {
    max-width: 140px;
  }
}

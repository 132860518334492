@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.product-comparison-card {
  font-weight: normal;
  color: $--color-black-grey;
  padding-right: 24px;
  margin-bottom: 35px;
}

.image {
  margin-bottom: 25px;
}

.name {
  font-size: 16px;
  @include font-weight(bold);

  height: calc(16px * 1.5715 * 2);
}

.number {
  font-size: 11px;
  color: $--color-dark-grey;
  text-transform: uppercase;
  margin-bottom: 25px;
  display: block;
}

.price {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 14px;
  text-transform: uppercase;
  color: $--color-black-grey;
  > span {
    &:first-child {
      font-size: 12px;
      font-weight: 500;
    }

    &:last-child {
      font-size: 18px;
      @include font-weight(extra-bold);
    }
  }
}

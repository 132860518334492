@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.roleForm {
  h1 {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .field {
    width: 100%;
  }

  .footer {
    margin-top: 50px;

    > button {
      width: 100%;
      margin-bottom: 12px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .roleForm {
    .fieldGroup {
      display: flex;
    }
    .field {
      width: 300px;
      margin-right: 12px;
    }

    .footer {
      display: flex;
      justify-content: space-between;

      > button {
        width: auto;
        margin-bottom: 12px;
      }
    }
  }
}

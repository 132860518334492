.ProductPortfolioEdit {
  margin-top: 32px;
  .footer {
    display: flex;
    margin-top: 32px;

    > button {
      margin-right: 16px;
    }
  }
}

.productConfigTable {
  &:global(.base-table .ant-table-thead > tr > th.ant-table-cell) {
    text-transform: none;
  }
}
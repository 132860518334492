.container {
  background-color: #f8f8f8;
  display: flex;
  padding: 28px 62px 20px 25px;
  justify-content: space-between;

  .image {
    width: 35%;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .heading {
    margin-bottom: 30px;
    display: block;
  }
}

@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.actionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roleManagementPage {
  .title {
    display: flex;
    justify-content: space-between;
    > h1 {
      font-weight: 900;
      font-size: 30px;
      line-height: 1.2;
      color: $--color-black-grey;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}

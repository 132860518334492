@import '../../assets/scss/variables';

.wrapper {
  margin: 25px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global {
  .ant-pagination {
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: transparent;
  }

  .ant-pagination-item {
    border: none;
    border-radius: 0;
    font-size: 12px;
    line-height: 22px;

    > a {
      color: $--color-black-grey;
    }

    &:hover {
      > a {
        color: $--color-black-grey;
      }
    }
  }

  .ant-pagination-item-active > a {
    font-weight: 500;
    color: $--color-black-grey;

    &:hover {
      color: $--color-black-grey;
    }
  }

  .ant-pagination-item:not(.ant-pagination-item-active) {
    color: $--color-black-grey;
  }

  .ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      &:hover {
        button {
          outline: none;
          border-color: unset;
        }
      }
    }

    .ant-pagination-next {
      margin-left: 8px;
    }

    .ant-pagination-prev {
      margin-right: 8px;
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    color: $--color-black-grey;
    font-size: 12px;
    line-height: 22px;

    .ant-pagination-item-container {
      .ant-pagination-item-link-icon {
        color: $--color-black-grey;
      }
    }
  }
}

.roundButton {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border:none;
  background-color: $--color-black;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    height: 12px;
    width: 12px;

    > path {
      fill: $--color-yellow;
    }
  }

  &[disabled] {
    background-color: $--color-grey;
    cursor: not-allowed;
    pointer-events: none;

    > svg > path {
      fill: $--color-dark-grey;
    }
  }
}
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 18px;
  padding: 0.6rem 0 2.2rem 1.2rem;
  background-color: $--color-light-grey;
}

.redeemVoucherContainer {
  margin-right: 25px;
}

.title {
  color: $--color-black-grey;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: calc(28 / 11);
  text-transform: uppercase;
  margin-bottom: 0;
}

.information {
  max-width: 320px;
  word-wrap: break-word;
  line-break: auto;
  color: $--color-black-grey;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: calc(22 / 13);
}

.voucherCode {
  font-weight: 900;
}

@include media-breakpoint-down(md) {
  .wrapper {
    padding: 1.7rem;
    flex-direction: column;
  }
  
  .redeemVoucherContainer {
    margin-right: unset;
    width: 100%;
  }

  .title {
    margin-right: 0;
    width: 100%;
  }

  .information {
    max-width: none;
    margin-top: 15px;
  }
}

@import '../../../assets/scss/variables';

.wrapper {
  :global {
    .anticon.anticon-caret-up.ant-table-column-sorter-up {
      line-height: 1px;
    }
    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
      line-height: unset;
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: $--color-black-grey;
    }
    
    .ant-table-column-sorters {
      padding: 0;
    }

    .ant-table-thead {
      tr {
        th.ant-table-cell {
          background-color: #D4D4D4;
          padding-top: 1.7rem;
          padding-bottom: 1.7rem;
          padding-left: 1.6rem;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          color: #000;
          font-size: 1.2rem;
          line-height: 16px;

          a {
            color: #000;
            display: block;
          }
        }
      }
    }

    .ant-table-cell .button-as-link {
      text-decoration: underline;
      font-weight: 500;
    }
  }
}


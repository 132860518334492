@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.sidebar {
  &__wrapper {
    width: 15%;
    transition: 0.5s ease;
    margin-top: -19px;

    &-homepage {
      margin-top: 1px;
    }

    a {
      color: rgba(0, 0, 0, 0.85);

      &:hover {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .ant-layout-sider {
      z-index: 2;
    }

    .ant-layout-sider {
      max-width: 100% !important;
      min-width: auto !important;
    }

    .ant-menu-inline {
      .ant-menu-item {
        &::after {
          display: none;
        }
      }
    }

    .ant-menu {
      &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          background-color: $--color-black-grey;

          *:first-child {
            color: $--color-yellow;
            font-weight: 700;

            svg {

              path,
              polygon {
                fill: $--color-yellow;
              }
            }
          }
        }
      }
    }

    .ant-menu-submenu {
      &-title {
        a {
          width: 100%;
          display: block;
        }

        &:hover {
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);

          a {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
          }

          .ant-menu-submenu-arrow {

            &::after,
            &::before {
              background: rgba(0, 0, 0, 0.85);
            }
          }
        }
      }
    }

    .ant-menu-item,
    .ant-menu-item-only-child {
      max-width: 100%;
      white-space: normal;

      &:hover {

        div:first-of-type,
        a {
          color: $--color-black;
          font-weight: 700;

          svg {

            path,
            polygon {
              fill: $--color-black;
            }
          }

          .my-workspace {
            &__icons {
              &--normal {
                display: none;
              }

              &--selected {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .ant-menu-submenu-open {
      .ant-menu-submenu-title {
        >a {
          font-weight: bold;
        }
      }
    }

    .ant-menu-submenu-selected {
      color: rgba(0, 0, 0, 0.85);
    }

    &.sticky-outer-wrapper {
      &.active {
        >.sticky-inner-wrapper {
          @media screen and (min-width: $--very-small-device-width) and (max-width: $--ipad--landscape-width) {
            transform: translate3d(0px, 170px, 0px) !important;
          }

          @media screen and (max-width: $--very-small-device-width) {
            transform: translate3d(0px, 200px, 0px) !important;
          }
        }
      }
    }
  }

  &__menu {
    background: $--color-anthracite-pro;
    width: 175px;

    @media screen and (max-width: $--ipad--landscape-width) {
      width: 100%;
    }

    border-right: 0;

    li {
      margin: 0;
      text-transform: uppercase;

      *:first-child {
        color: $--color-white;
        display: inline-flex;
        align-items: center;

        svg {

          path,
          polygon {
            fill: $--color-white;
          }
        }
      }

      &.ant-menu-item-divider {
        border-top-color: $--color-dark-grey;
        margin: 13px 8px;
      }
    }

    &__item {
      &--badged {
        width: 55%;
      }

      &--bordered {
        position: relative;
      }
    }
  }

  &__drawer {
    .ant-drawer-body {
      padding: 40px 0 24px 0;
      background: $--color-anthracite-pro;
    }

    .ant-drawer-close {
      padding: 15px 20px 20px 20px;
    }

    .sidebar__menu {
      border-right: none !important;
      border-bottom: none !important;

      .ant-menu-item {
        padding: 5px 10px !important;
        max-width: 100%;
        margin: 0 !important;

        a {
          font-weight: bold;
        }

        &::after {
          display: none;
        }
      }

      .ant-menu-item-selected {
        background-color: $--color-black-grey;

        div:first-of-type,
        a {
          color: $--color-yellow;
        }
      }
    }
  }

  &__header {
    @media screen and (max-width: $--ipad--landscape-width) {
      background: $--color-anthracite-pro;
      margin-bottom: 0px;
    }

    @include font-weight(extra-bold, 900);
    text-transform: uppercase;
    padding-left: 9px;
    font-size: 20px;
    margin-bottom: 8px;

    >span {
      display: block;
      max-width: 175px;
      white-space: normal;
      word-wrap: break-word;
      line-height: 1;
    }
  }

  &__collapse {
    &--wrapper {
      border: none;
      padding-bottom: 0 !important;

      .ant-collapse {
        &-item {
          border-bottom: none;
          border-radius: 0 !important;

          &.sidebar__header {
            margin-left: -(map-get($spacers, 4));
            padding-left: map-get($spacers, 4);
          }
        }

        &-header {
          padding: 0 !important;
          padding-left: map-get($spacers, 4) !important;
          color: $--color-white !important;
        }

        &-arrow {
          top: 6px !important;

          &>g>use {
            fill: $--color-white;
          }
        }

        &-content {
          &-active {
            border: none !important;
          }

          &-box {
            padding: 0 !important;
          }
        }
      }
    }
  }

  //Responsive
  @media screen and (max-width: 1330px) {
    &__wrapper {

      .ant-layout-sider,
      .ant-layout-sider-dark {
        width: 160px !important;
      }
    }
  }

  @media screen and (max-width: $--ipad--landscape-width) {
    &__wrapper {
      position: relative;
      top: 60px;
    }

    &__icons-wrapper {
      padding-left: 20px;
      padding-bottom: 15px;
    }
  }

  @media screen and (max-width: 425px) {
    &__icons-wrapper {
      padding-bottom: 5px;
    }
  }
}

ul.sidebar__menu.ant-menu-inline {
  .ant-menu-item {
    display: flex;
    align-items: center;
    min-width: 0;
    height: unset;
    margin: 0;
    padding: 3px 10px !important;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;

    >* {
      height: auto;
      min-height: 28px;

      >span {
        display: inline-block;
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@mixin b2bec-modal {
  .ant-modal {
    &-content {
      padding: 34px 43px 62px;
    }
    &-header,
    &-footer {
      padding: 0;
      margin: 0;
      border: none;
      button {
        box-sizing: border-box;
        padding: 11px 11px 10px;
        @include font-weight(bold);
        border-radius: 0;
        > span:last-child {
          min-width: 66px;
          line-height: 19px;
        }
      }
    }
    &-title {
      text-transform: uppercase;
      font-size: 30px;
      color: $--color-black-grey;
      @include font-weight(extra-bold);
      line-height: 36px;
    }
    &-body {
      margin: 14px 0 0;
      padding: 0;
      @include font-size(normal);
      > div {
        white-space: pre-wrap;
      }
    }
    &-footer {
      margin-top: 49px;
    }
  }
}

.input {
  display: flex;

  :global {
    .material-input,
    .ant-input {
      width: 100%;
      margin-bottom: 0px;
      margin-right: 12px;
    }
  }
}
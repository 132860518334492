@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.B2BecCartSummary {
  background-color: $--color-light-grey;
  padding: 16px;

  .subtitle {
    font-size: $--font-size-11;
    padding-bottom: 20px;
    @include media-breakpoint-up(md) {
      @include font-size(normal);
    }
  }

  .subtitle__value {
    text-align: right;
  }


  .bold {
    font-weight: bold;
  }

  .uppercase {
    text-transform: uppercase;
  }
}

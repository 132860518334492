@use 'sass:math';

$--font-size-10: 10px;
$--font-size-11: 11px;
$--font-size-12: 12px;
$--font-size-14: 14px;
$--font-size-15: 15px;
$--font-size-16: 16px;
$--font-size-20: 20px;
$--font-size-30: 30px;
$--font-size-very-small: 11px;
$--font-size-small: 12px;
$--font-size-base: 13px;
$--font-size-medium: 18px;
$--font-size-large: 24px;
$--font-size-xl: 32px;
$--font-size-2xl: 48px;

$--transition-all: all 450ms ease-in-out;
$--width-page-container: 1440px;
$--width-container: 1240px;
$--min-width-page-container: 1096px;

$--max-width-main-layout: 70%;
$--ipad--landscape-width: 1180px;
$--small-device-width: 640px;
$--very-small-device-width: 480px;
$--extreme-small-device-width: 375px;
$--screen-1650: 1650px;

// Header
$header-height: 56px;
$header-height--desktop: 88px;

// Search
$search-bar-height: 56px;
$search-bar-height--desktop: 44px;

//Loading Icons
$--dot-width: 10px !default;
$--dot-height: 10px !default;
$--dot-radius: math.div($--dot-width, 2) !default;

$--dot-color: #9880ff !default;
$--dot-bg-color: $--dot-color !default;
$--dot-before-color: $--dot-color !default;
$--dot-after-color: $--dot-color !default;

$--dot-spacing: $--dot-width + math.div($--dot-width, 2) !default;

//Material Design Box Shadow
$--material-design-box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25),
  1px 1px 3px rgba(0, 0, 0, 0.1);

// Spacing
$spacers: (
  0: 0,
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 24px,
  6: 48px,
  7: 96px,
) !default;

$sides: (
  t: top,
  l: left,
  r: right,
  b: bottom,
) !default;

$grid-breakpoints: (
  xxs: 0,
  xs: 480px,
  sm: 576px,  // landscape phones
  md: 768px,  // tablets
  lg: 992px,  // desktop
  xl: 1200px, // large desktops
  xxl: 1600px,
) !default;

$valid-font-sizes: (
  small: $--font-size-small,
  normal: $--font-size-base,
  medium: $--font-size-medium,
  large: $--font-size-large,
  xl: $--font-size-xl,
  2xl: $--font-size-2xl,
) !default;

$valid-font-weights: (
  normal: 400,
  medium: 500,
  bold: 700,
  extra-bold: 900,
) !default;

//Color Variables

$--color-dangerous: #f54f64;
$--color-success: #65db75;
$--color-processing: #1890ff;

$--color-link: #2b368b;
$--color-tag: #538dff;
$--color-line: #d8dce6;

$--color-text-secondary: #828894;
$--color-black: #000;
$--color-black-grey: #2b2b2b; // update name: WEB BLACK
$--color-anthracite-pro: #6e6e6e;
$--color-boulder: #747474;
$--color-dark-grey: #757575;
$--color-light-grey: #ededed;
$--color-bg-bright-grey: #f5f5f5;
$--color-bright-grey: #f8f8f8;
$--color-white: #fff;
$--color-yellow: #ffed00;
$--color-petrol: #238c96;
$--color-positive-green: #4fae47;
$--color-alarm-error: #cc0000;
$--color-white-smoke: #f4f4f4;
$--color-gainsboro: #dcdcdc;
$--color-dusty-gray: #979797;
$--color-grey: #d8d8d8;
$--color-cornflower-blue: #bcbcbc;
$--color-neutral-grey: #F1F1F1;

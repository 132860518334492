@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.confirm-modal {
  :global(.ant-modal) {
      .ant-modal-content {
        padding: 34px 22px;
      }
    .ant-modal-header,
    .ant-modal-footer {
        border: none;
        button {
          @include font-weight(bold);
        }
      }
    .ant-modal-body {
        width: 80%;
        margin-bottom: 30px;
        padding-top: 8px;
        @include font-size(normal);
        > div {
          white-space: pre-wrap;
        }
      }
    .ant-modal-title {
        text-transform: uppercase;
        font-size: 30px;
        color: $--color-black-grey;
        @include font-weight(extra-bold);
      }
    }

  button {
    @media screen and (max-width: $--very-small-device-width) {
      width: 100%;
      margin: 10px 0;
    }
  }
}


@import '../../../../assets/scss/variables';

.searchTerm {
  padding: 0 12px;
}

.searchTermInner {
  border: 1.5px solid $--color-dark-grey;
  line-height: 32px;
  padding: 0 0 0 12px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  color: $--color-dark-grey;
  font-weight: 500;

  > button {
    color: $--color-dark-grey;
  }
}

.listSearchTerm {
  display: flex;
  margin: 0 -12px;
}

.address {
  width: 33.3333%;
  padding: 12px;
}

.addressInner {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;
  border: 2px solid transparent;
  height: 100%;
}

.addressSelected {
  border: 2px solid black;
}

.listAddress {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.inputWrapper {
  position: relative;
  top: -24px;
  left: -24px;
  width: calc(100% + 50px);

  display: flex;
  justify-content: space-between;
  padding: 58px 12px 16px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.startPosition {
  position: absolute;
  left: 24px;
}

.searchingInput {
  left: 30px;
  top: -7px;

  .searchingButton, .searchingButton:active, .searchingButton:focus, .searchingButton:hover {
    width: 90%;
  }

  .closeButton {
    position: absolute;
    left: 100%;
  }
}

.searchTitle {
  display: inline-block;
  position: relative;
  top: -2px;
  left: 10px;
}

.totalResult {
  margin-top: 12px;
}

.searchAddressInput {
  max-width: 380px;
}

@media screen and (max-width: 768px) {
  .address {
    width: 100%;
    padding: 12px;
  }
}

@import '../variables';
@import '../mixins';
@import '../formItems/customInputMixin';

$--font-size-11: 11px;
$--font-size-13: 13px;

@mixin custom-search-input() {
  @include custom-input();
  padding: 0 !important;
  padding-left: 11px !important;
  margin-bottom: 0;
  border: 2px solid $--color-dark-grey !important;

  .ant-input {
    padding-top: 4px !important;
    padding-left: 18px !important;

    &-affix-wrapper {
      border: none !important;
      &-focused {
        border: none !important;
        box-shadow: none;
      }
    }
    &-suffix {
      padding: 0px;
    }
    &-group-addon {
      padding: 13px 13px 12px;
      @include font-weight(extra-bold);
      &__size {
        &--small {
          padding: 9px 13px;
        }
      }
      height: auto;
      transition: all 0.6s ease-out;
      .ant-btn-primary {
        color: $--color-black-grey;
        border: none;
        border-left: 2px solid $--color-dark-grey ;
        background: transparent;
        &:hover,
        &:active,
        &:focus {
          border-left: 2px solid $--color-black-grey;
        }
      }
    }
  }
}
@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.employeeInformation {
  display: flex;
  flex-wrap: wrap;

  h2 {
    flex: 100%;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 18px;
    text-transform: uppercase;
  }
}

.userData {
  display: flex;
  flex-direction: column;
  flex: 50%;
  margin-bottom: 16px;
}

.tag {
  border-radius: 12px;
  height: 24px;
  background-color: #D8D8D8;
  color: #2B2B2B;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  span {
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px;
    line-height: 24px;
  }

  .button {
    cursor: pointer;
  }
}
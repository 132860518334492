@import './mixins/boxshadow';
@import './mixins/modal';
@import './breakpoints';

@mixin dot(
  $width: $--dot-width,
  $height: $--dot-height,
  $radius: $--dot-radius,
  $bg-color: $--color-yellow,
  $color: $--color-yellow
) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bg-color;
  color: $color;
}

@mixin font-face($name, $path, $weight: null, $style: normal) {
  @font-face {
    font-display: swap;
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    src: url('#{$path}.eot') format('eot'), url('#{$path}.woff') format('woff');
  }
}

@mixin font-weight($weight-type: normal, $default: 400) {
  $weight: map-get($valid-font-weights, $weight-type);

  @if $weight == null {
    @error "$weight-type not exist";
  }

  font-weight: if($weight != null, $weight, $default);
}

@mixin font-size($size-type: normal, $default: $--font-size-base) {
  $size: map-get($valid-font-sizes, $size-type);

  @if $size == null {
    @error "$size-type not exist";
  }

  font-size: if($size != null, $size, $default);
}

@mixin generate-spacing($infix, $spacers: $spacers) {
  @each $space, $value in $spacers {
    $property-class-modifier: if(
      $infix == null,
      '#{$space}',
      '#{$infix}-#{$space}'
    );

    .m-#{$property-class-modifier} {
      margin: #{$value};
    }

    .p-#{$property-class-modifier} {
      padding: #{$value};
    }

    @each $side, $side-name in $sides {
      .m#{$side}-#{$property-class-modifier} {
        margin-#{$side-name}: #{$value} !important;
      }
      .p#{$side}-#{$property-class-modifier} {
        padding-#{$side-name}: #{$value} !important;
      }
    }
  }
}

@mixin animated($animate-duration, $animation-name) {
  animation-duration: $animate-duration;
  animation-fill-mode: both;
  animation-name: $animation-name;
}

@mixin container-normalize {
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
}

@mixin wrapper-size($size: 70%) {
  @media screen and (min-width: $--very-small-device-width) {
    width: $size;
    margin: auto;
  }
}

@mixin link-normalize($color: #333) {
  color: $color;
  text-decoration: none;
}

@mixin button-hover($inverse: false) {
  outline: none;
  color: $--color-yellow;

  @if $inverse {
    background-color: $--color-black-grey;
    border-color: $--color-yellow;
  } @else {
    background: $--color-black-grey;
    border-color: $--color-black-grey;
  }
}

//String replace function
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@mixin b2bwrapper {
  margin-top: 70px;

  &__header {
    > h1 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 36px;
      margin-bottom: 12px;
    }

    margin-bottom: 30px;
  }
}

@mixin ellipsis-texts($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin iconRules() {
  svg {
    use {
      @content
    }
  }
}

@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.service-link {
  display: block;
  padding: 1.2rem;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: $--color-black-grey;
  background-color: $--color-white;
  border: solid 2px transparent;
  transition: all ease-out 0.3s;

  div {
    margin-bottom: map-get($spacers, 3);
  }

  &:hover {
    color: $--color-black-grey;
    border-color: $--color-yellow;
  }
}

.service-link__title {
  color: $--color-black-grey;
  display: inline-block;
}
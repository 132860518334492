@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.modal {
  max-width: 540px;
  max-height: 773px;

  :global(.ant-modal-body) {
    padding: 4rem 4rem 3.2rem;
  }
}

.modal__title {
  color: $--color-black-grey;
  font-size: 3rem;
  font-weight: 900;
  line-height: calc(36 / 30);
  text-transform: uppercase;
}

.wishlists {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.new-wishlist__header {
  color: $--color-black-grey;
  font-size: 1.1rem;
  line-height: calc(28 / 11);
  font-weight: 700;
  text-transform: uppercase;
}

.break-line {
  margin: 24px auto;
  border-top: 1px solid $--color-light-grey;
}

.modal__action-button {
  border: none;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0.9rem 1.3rem;
  letter-spacing: normal;
  display: block;
  margin-left: auto;
}

.product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $--color-dark-grey;
  margin-bottom: 24px;
  padding: 0.4rem 1.6rem 0.4rem 0.4rem;
}

.product > .image {
  flex: 1 1 10%;
  margin-right: 10px;
  max-width: 72px;

  > img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.product > .content {
  flex: 1 1 auto;

  .material-name {
    color: $--color-black-grey;
    display: block;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: calc(22 / 13);
    margin-bottom: 8px;
    width: auto;
    max-width: 25ch;
  }

  .sub-information {
    color: $--color-black-grey;
    display: block;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: calc(22 / 13);
  }
}

.quantity {
  width: 48px;

  :global(.ant-input-number-input) {
    color: $--color-dark-grey;
    font-size: 1.3rem;
    font-weight: 500;
    height: 34px;
    line-height: calc(22 / 13);
  }
}

.confirmation-modal__button {
  border: none;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0.9rem 1.3rem;
  letter-spacing: normal;

  + .confirmation-modal__button {
    margin-left: 8px;
  }
}

@include media-breakpoint-down(lg) {
  :global(.ant-modal-centered::before) {
    content: unset;
  }

  :global(.ant-modal-centered>.ant-modal) {
    display: inline;
  }

  .modal {
    max-width: 100vw;
    height: 100%;
    border: 0;
    border-radius: 0;
    margin: unset;
    padding: unset;

    :global(.ant-modal-body) {
      padding: 3rem 1.6rem;
    }

    .modal__title-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 16px;

      .modal__close-button {
        margin-right: 8px;
      }

      .modal__title {
        font-size: 1.4rem;
        margin-bottom: 0;
      }
    }
  }

  .wishlists {
    max-height: unset;
  }

  .product > .image {
    margin-right: 8px;
    max-width: 50px;
  }

  .product > .content {
    .material-name {
      max-width: 40ch;
    }

    .sub-information {
      font-size: 1.1rem;
    }
  }

  .quantity {
    width: 40px;

    :global(.ant-input-number-input) {
      font-size: 1.1rem;
      line-height: calc(22 / 11);
    }
  }
}

@include media-breakpoint-down(md) {
  .product > .content {
    .material-name {
      max-width: 20ch;
    }
  }
}

@include media-breakpoint-down(xs) {
  .product > .content {
    .material-name {
      max-width: 16ch;
    }
  }
}
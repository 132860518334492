@import '../../../../../../assets/scss/mixins';
@import '../../../../../../assets/scss/variables';

.wrapper {
  @include font-size(normal);
  line-height: 22px;
}

.title {
  font-size: 14px;
  @include font-weight(bold);
  line-height: 28px;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.subTitle {
  @include font-weight(bold);
}

.editUserInfoBtn {
  margin-top: 42px;
}

.assignedRolesWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.assignedRole {
  margin-right: 0;
  padding: 2px 6px;
  font-weight: 500;
}
@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.wrapper {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.machines {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
  
}
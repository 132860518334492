@import "../../assets/scss/variables";

.autoComplete {
  width: 100%;

  :global(.ant-select-selector) {
    width: 100%;
  }
  input:disabled,
  input:hover:disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      cursor: not-allowed;
      opacity: 1;
  }
}

.searchSuggestion {
  position: relative;
  display: flex;

  input {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    line-height: 1;

    /* Browsers have different default form fonts */
    font-size: 13px;
    font-family: Arial;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;


    border: 2px solid #757575;
    padding: 11px 24px 11px 44px;

    flex: 1;
  }

  /* Remove the stupid outer glow in Webkit */
  input:focus {
    outline: 0;
  }

  .icon {
    position: absolute;
    height: 24px;
    top: 8px;
    padding: 0 10px;
  }

  .searchButton {
    position: relative;
    border: 2px solid #2B2B2B;
    left: -1px;
    text-align: center;
    line-height: 41px;
    padding: 0 24px;
    font-size: 15px;
    font-weight: bold;
  }
}

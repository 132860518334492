@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/mixins";

.usersTable {
  margin-top: 22px;

  :global {
    .ant-table-cell {
      font-size: 11px;
      color: $--color-black-grey;
    }

    th {
      text-transform: capitalize !important;

      &.ant-table-column-sort.ant-table-column-has-sorters {
        span.ant-table-column-sorter-inner {
          span.ant-table-column-sorter-up.active,
          span.ant-table-column-sorter-down.active {
            color: $--color-black-grey;
          }
        }
      }
    }

    tbody.ant-table-tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

.lastLogin {
  display: inline-block;
  line-height: 14px;
  font-weight: 500;
}

.assignedRolesWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.assignedRole {
  margin-right: 0;
  padding: 2px 6px;
}

.userStatus {
  border-radius: 15px;
  padding: 3px 13px;
  @include font-weight(medium);
  color: $--color-white;
  display: flex;
  align-items: center;
  justify-content: center;

  &--green {
    background-color: $--color-positive-green;
  }
  &--gray {
    background-color: $--color-anthracite-pro;
  }
  &--light-gray {
    background-color: $--color-dusty-gray;
  }
  &--red {
    background-color: $--color-alarm-error;
  }
}

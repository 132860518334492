@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixins';

.button {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  display: inline-flex;
  align-items: center;
  color: $--color-black-grey;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: none;

  &:hover,
  &:focus,
  &:active {
    color: $--color-black-grey;
    background: transparent;
    border-color: transparent;
  }
}

.icon {
  margin-top: 4px;
}

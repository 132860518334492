@import '../../../../../assets/scss/variables';

.wrapper {
  width: 100%;
  padding: 32px 0 18px;
  background-color: $--color-bg-bright-grey;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 17px;

  @media (min-width: $--ipad--landscape-width + 1) {
    width: 70%;
    margin: 0 auto;
  }
}

.heading {
  font-weight: 900;
  font-size: 3rem;
  line-height: 1.2;
  color: $--color-black-grey;
  text-transform: uppercase;
  margin-bottom: 0;
}

.button {
  font-weight: 700;
}
@import '../../../../../assets/scss/formItems/customInputMixin';

.wishlist-search {
  @include custom-input();
}

.search-icon {
  transform: rotateY(180deg);
  .ant-input-prefix {
    margin-right: 10px!important;
  }
}

.modal :global {
  .ant-modal {
    height: 100vh;
    width: 100vw !important;
    margin: 0;
    top: 0;
    .ant-modal-body {
      height: calc(100vh - 10px);
      overflow-y: auto;
    }
    .ant-modal-content, .ant-modal-wrap {
      height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0;
    }
  }
}
@import '../../assets/scss/mixins';

.container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  width: 100%;
}

.cardBody {
  padding: 24px;

  > a {
    display: block;
    margin-bottom: 17px;
    margin-top: 3px;
  }

  :global {
    .ant-image {
      margin: 0 auto;
      display: block;
    }
  }
}

.toggleMessageButton {
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
}

.orderDate {
  background-color: #2b2b2b;
  color: #fff;
  padding: 0.3125em 0.5em;
  border-radius: 1px;
  max-width: fit-content;
}

.categoryName {
  margin-bottom: 6px;
  color: #2b2b2b;
  font-size: 11px;
  font-weight: 400;
}

.cardItemTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.288;
  color: #000;
  margin-bottom: 11px;
  @include ellipsis-texts(2);
  text-transform: none;
  padding-left: 8px;
  padding-right: 8px;
}

.cardItemNumber {
  margin-bottom: 10px;
  line-height: 1;
  color: #757575;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  padding-left: 8px;
  padding-right: 8px;
}

.cardButtonWrapper {
  margin-top: 40px;
  display: grid;

  > :first-child {
    margin-bottom: 17px;
  }
}

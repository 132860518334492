@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.enquiryModule {
  overflow: hidden;

  :global {
    .enquiry-modal {
      .scrollable {
        margin: -12px;
        padding: 12px;
        height: calc(100vh - 380px);
        overflow: hidden;
        overflow-y: auto;
        margin-bottom: 24px;
      }

      &__header {
        font-size: 30px;
        @include font-weight(extra-bold);
        text-transform: uppercase;
      }

      &__description {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 30px;
        width: 80%;
      }

      &__form {
        margin-bottom: 70px;
        .custom-form-item {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 25px;
          }
        }
      }

      &__footer {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }

      .ant-modal-body {
        padding: 30px 40px 20px;
      }

      &-error {
        color: $--color-dangerous;
        margin-bottom: 24px;
      }

      &-success {
        text-align: center;

        .anticon-check-circle {
          font-size: 72px;
          color: $--color-success;
          margin-bottom: 24px;
        }

        .custom-button {
          margin: 0 auto;
        }

        &__message {
          margin-bottom: 24px;
          display: block;
        }
      }
    }
  }
}

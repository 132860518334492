@import '../../../../assets/scss/variables';

.datePeriodWrapper {
  :global {
    .ant-picker-focused {
      box-shadow: none;
      border-right-width: 2px !important;
    }

    .ant-picker:hover,
    .ant-picker-focused {
      border: 2px solid $--color-black-grey !important;
    }

    .ant-picker-dropdown {
      .ant-picker-panel-container {
        position: inherit;
      }
    }
  }

  .dropdown {
    :global {
      .ant-picker-header-view {
        button:hover {
          color: inherit;
          opacity: 0.7;
        }
      }
  
      .ant-picker-cell-in-view.ant-picker-cell-range-start,
      .ant-picker-cell-in-view.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background: $--color-yellow;
          color: $--color-black-grey;
        }
      }
  
      .ant-picker-cell-in-view.ant-picker-cell-today {
        .ant-picker-cell-inner::before {
          border-color: $--color-black-grey;
        }
      }
  
      .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
      .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
      .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background: $--color-bg-bright-grey;
      }

      .ant-picker-date-range-wrapper {
        .ant-picker-panels {
          width: inherit;
        }
      }
    }

    z-index: 2;
  }

  .picker {
    border-width: 2px;
    border-color: $--color-dark-grey;
    padding: 0.8rem 1.1rem;

    :global {
      .ant-picker-suffix {
        color: $--color-black;
        font-size: 1.6rem;
      }
      
      .ant-picker-clear {
        font-size: 1.6rem;
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        > * {
          vertical-align: unset;
        }
      }

      .ant-picker-active-bar {
        background: $--color-black-grey;
      }

      input, input::placeholder {
        color: $--color-black-grey;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: calc(22 / 13);
      }
  
      input::placeholder {
        color: $--color-dark-grey;
      }
    }
  }
}

.title {
  color: $--color-black-grey;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: calc(28 / 11);
  text-transform: uppercase;
}
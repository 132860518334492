@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.title {
  font-size: 3rem;
  margin: 30px 0;
  text-transform: uppercase;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3.2rem;
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@include media-breakpoint-down(lg) {
  .wrapper {
    position: relative;
    top: 20px;
  }
}
@import "../../assets/scss/_variables.scss";

.button {
  letter-spacing: 0.5px;
  padding: 12px 35px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 19px;
  border: none;
  cursor: pointer;
  border: solid 2px $--color-yellow;
  color: $--color-black-grey;

  &:not(.ghost,.disabled) {
    background-color: $--color-yellow;

    &:hover {
      background-color: $--color-black-grey;
      border-color: $--color-black-grey;
      color: $--color-yellow;
      transition: all 0.25s ease-in-out;
    }
  }
}

.icon + .content,
.content + .icon {
  margin-left: 8px;
}

.icon {
  display: inline-block;
  width: 24px;
  text-align: center;
}

.loading {
    opacity: 0.65;
    pointer-events: none;
  }

.ghost {
  color: $--color-black-grey;
  background: transparent;
  border-color: $--color-black-grey;

  &:hover {
    background-color: $--color-black-grey;
    color: $--color-yellow;
  }
}

.disabled {
    background: $--color-light-grey;
    color: #b3b3b3;
    border-color: $--color-light-grey;
    pointer-events: none;
  }

.block {
  display: block;
  width: 100%;
}



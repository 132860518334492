@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.OrderDetailsPageTitle {
  margin: 45px 0 32px 0;
  max-width: 100%;

  h1 {
    margin-bottom: 12px;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: $--color-black-grey;
  }

  h3 {
    font-size: 14px;
    @include font-weight(bold);
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .shipmentLink {
    text-decoration: underline;
    color: $--color-black-grey;
    cursor: pointer;
    display: block;
  }
}

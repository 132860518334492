@import '../../assets/scss/variables';

.headerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 130px;
  background-color: $--color-yellow;
  color: $--color-black-grey;
  padding: 0;
  padding-bottom: 6px;
}

.headerContainer {
  width: $--max-width-main-layout;
  margin: 0 auto;
}

.headerWelcomeTitle {
  font-weight: 900;
  font-size: 2.8rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

.headerWelcomeText {
  font-weight: 500;
  font-size: 1.8rem;
}

.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.loginButton {
  width: 200px;
  text-transform: uppercase;
  font-weight: 700;

  &:active,
  &:focus {
    background-color: $--color-yellow;
    outline: none;
  }
}

@media screen and (max-width: 768px) {
  .headerWelcomeTitle {
    font-size: 2.2rem;
  }

  .headerWelcomeText {
    font-size: 0.8rem;
  }

  .contentContainer {
    min-height: 350px;
  }
}

@media screen and (max-width: 410px) {
  .headerWelcomeTitle {
    font-size: 2rem;
  }

  .headerWelcomeText {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 320px) {
  .headerWelcomeTitle {
    font-size: 1.8rem;
  }
  .headerWelcomeText {
    font-size: 0.7rem;
  }
}
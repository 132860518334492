@import '../../../assets/scss/variables';
.suggestionItem {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global(.suggestion-item__name) {
    width: 100%;
  }
}
